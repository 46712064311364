exports.components = {
  "component---src-pages-articulo-js": () => import("./../../../src/pages/articulo.js" /* webpackChunkName: "component---src-pages-articulo-js" */),
  "component---src-pages-big-data-analysis-startup-js": () => import("./../../../src/pages/big-data-analysis-startup.js" /* webpackChunkName: "component---src-pages-big-data-analysis-startup-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-details-js": () => import("./../../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-casos-de-exito-js": () => import("./../../../src/pages/casos-de-exito.js" /* webpackChunkName: "component---src-pages-casos-de-exito-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-data-analytics-ai-startup-js": () => import("./../../../src/pages/data-analytics-ai-startup.js" /* webpackChunkName: "component---src-pages-data-analytics-ai-startup-js" */),
  "component---src-pages-data-analytics-ml-consulting-js": () => import("./../../../src/pages/data-analytics-ml-consulting.js" /* webpackChunkName: "component---src-pages-data-analytics-ml-consulting-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-data-science-online-courses-js": () => import("./../../../src/pages/data-science-online-courses.js" /* webpackChunkName: "component---src-pages-data-science-online-courses-js" */),
  "component---src-pages-digital-marketing-agency-js": () => import("./../../../src/pages/digital-marketing-agency.js" /* webpackChunkName: "component---src-pages-digital-marketing-agency-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machine-learning-ai-solutions-js": () => import("./../../../src/pages/machine-learning-ai-solutions.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-solutions-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-politica-de-seguridad-js": () => import("./../../../src/pages/politica-de-seguridad.js" /* webpackChunkName: "component---src-pages-politica-de-seguridad-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-seo-agency-js": () => import("./../../../src/pages/seo-agency.js" /* webpackChunkName: "component---src-pages-seo-agency-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-servicios-cobranza-automatizada-js": () => import("./../../../src/pages/servicios/cobranza-automatizada.js" /* webpackChunkName: "component---src-pages-servicios-cobranza-automatizada-js" */),
  "component---src-pages-servicios-escritorio-digital-js": () => import("./../../../src/pages/servicios/escritorio-digital.js" /* webpackChunkName: "component---src-pages-servicios-escritorio-digital-js" */),
  "component---src-pages-servicios-factura-electronica-js": () => import("./../../../src/pages/servicios/factura-electronica.js" /* webpackChunkName: "component---src-pages-servicios-factura-electronica-js" */),
  "component---src-pages-servicios-flujos-de-aprobacion-js": () => import("./../../../src/pages/servicios/flujos-de-aprobacion.js" /* webpackChunkName: "component---src-pages-servicios-flujos-de-aprobacion-js" */),
  "component---src-pages-servicios-gestion-de-compras-js": () => import("./../../../src/pages/servicios/gestion-de-compras.js" /* webpackChunkName: "component---src-pages-servicios-gestion-de-compras-js" */),
  "component---src-pages-servicios-gestion-de-contratos-js": () => import("./../../../src/pages/servicios/gestion-de-contratos.js" /* webpackChunkName: "component---src-pages-servicios-gestion-de-contratos-js" */),
  "component---src-pages-servicios-portal-proveedores-js": () => import("./../../../src/pages/servicios/portal-proveedores.js" /* webpackChunkName: "component---src-pages-servicios-portal-proveedores-js" */),
  "component---src-pages-servicios-rendicion-de-gastos-js": () => import("./../../../src/pages/servicios/rendicion-de-gastos.js" /* webpackChunkName: "component---src-pages-servicios-rendicion-de-gastos-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terminos-de-servicio-js": () => import("./../../../src/pages/terminos-de-servicio.js" /* webpackChunkName: "component---src-pages-terminos-de-servicio-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

